<template>
  <!-- 折线图 -->
  <div>
    <div class="myChart-line" ref="myChart09"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Array,
      default: () => [],
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart09);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart09);
      }
      let list = this.DataList
      var option = {
        backgroundColor: "#263765",
        title: {
          textStyle: {
            color: "#A8C5E7",
            fontSize: 25,
          },
          top: "10%",
          left: "center",
          // 		right: '5%'
        },
        grid: {
          left: "12%",
          top: "13%",
          bottom: "13%",
          right: "10%",
        },
        xAxis: {
          // name: 'X',
          nameTextStyle: {
            color: "#A8C5E7",
            padding: [0, 0, 0, 20],
          },
          show: true,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#E0E6F1 ",
              shadowColor: "rgba(244,244,244,1)",
              shadowOffsetX: "20",
            },
            symbol: ["none", "arrow"],
            symbolOffset: [0, 25],
          },
          splitLine: {
            show: false,
            lineStyle: {
              color: "rgba(255,255,255,0.2)",
            },
          },
          axisLabel: {
            show: true,
            // rotate: -1,
            textStyle: {
              fontSize: 14,
              // fontFamily: PangMenZhengDao,
              fontWeight: 600,
              color: "#A8C5E7",
            },
          },
          axisTick: {
            show: false,
          },
          // 		data: ['物业纠纷', '其他合同', '道路交通']
          data: list.map((val) => {
            return val.name;
          }),
        },
        yAxis: [
          {
            name:'单位（人）',
            nameTextStyle: {
              color: "#eeeeee",
              padding: [0, 0, 0, 0],
            },
           
            show: true,
            axisTick: {
              show: false,
            },
            axisLine: {
              show: true,
              symbol: ["none", "arrow"],
              symbolOffset: [0, 15],
              lineStyle: {
                // color: 'rgba(255, 129, 109, 0.1)',
                width: 1, //这里是为了突出显示加上的
                color: "#E0E6F1",
                shadowColor: "rgba(91,100,134,1)",
              },
            },
            axisLabel: {
              show: true,
              textStyle: {
                fontSize: 12,
                // fontFamily: PangMenZhengDao,
                fontWeight: 600,
                color: "#A8C5E7",
              },
            },
            splitArea: {
              areaStyle: {
                color: "rgba(255,255,255,.5)",
              },
            },
            splitLine: {
              show: false,
              lineStyle: {
                color: "#cfe9f9",
                width: 1,
                type: "solid",
              },
            },
          },
        ],
        series: [
          {
            type: "pictorialBar",
            barCategoryGap: "-20%",
            /*多个并排柱子设置柱子之间的间距*/
            // symbol: 'path://M0,10 L10,10 C5.5,10 5.5,5 5,0 C4.5,5 4.5,10 0,10 z',
            symbol:
              "path://M0,10 L10,10 C5.5,10 6.5,5 5,5 C3.5,5 4.5,10 0,10 z",
            label: {
              show: true,
              position: "top",
              distance: 10,
              color: "#A8C5E7",
              fontWeight: "bolder",
              fontSize: 12,
            },
            itemStyle: {
              // normal: {
              //   color: {
              //     type: 'linear',
              //     x: 0,
              //     y: 0,
              //     x2: 0,
              //     y2: 1,
              //     colorStops: [
              //       {
              //         offset: 0,
              //         color: '#3b89f4',
              //       },
              //       {
              //         offset: 1,
              //         color: '#cce7fc',
              //       },
              //     ],
              //     global: false, //  缺省为  false
              //   },
              // },
              // emphasis: {
              //   opacity: 1,
              // },
              normal: {
                color: (params) => {
                  const colorList = [
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#297ff2",
                        },
                        {
                          offset: 1,
                          color: "#cce7fc",
                        },
                      ],
                    },
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#3bfafe",
                        },
                        {
                          offset: 1,
                          color: "#aaf4fe",
                        },
                      ],
                    },
                    {
                      type: "linear",
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: "#e08440",
                        },
                        {
                          offset: 1,
                          color: "#decabd",
                        },
                      ],
                    },
                  ];
                  return colorList[params.dataIndex];
                },
                opacity: 0.7,
              },
              // 鼠标移入柱子上 透明度变为 1
              emphasis: {
                opacity: 1,
              },
            },
            data: list.map((v) => {
              return v.value;
            }),
            // data: [123, 60, 25]
          },
        ],
      };

      //将配置项设置进去
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },

  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  width: 100%;
  height: 320px;
}
</style>
