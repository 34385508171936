<template>
  <div id="Data-center" class="main-wrap">
    <div class="main-margin">
      <div class="head-title">
        <span class="text">学习用户画像</span>
      </div>
      <div class="echarts-box">
        <div class="course-inner" @click="productShow = true">
          <span>{{courseName}}</span>
          <img class="icon" src="@/assets/image/dataCenter/down.png" alt="">
        </div>
        <div class="people-inner flex-div">
          <img class="icon" src="@/assets/image/dataCenter/title-left.png" alt="">
          <div class="people-item" v-for="(item,index) in vivewList" :key="index">
            <p>{{item.name}}：<span>{{item.value}}</span>人</p>
          </div>
          <img class="icon" src="@/assets/image/dataCenter/title-right.png" alt="">
        </div>
        <div class="echarts-inner">
          <div class="title-bg">
            <span>课程学习人数</span>
          </div>
          <div class="border-container border-container-left-bottom"></div>
          <div class="border-container border-container-right-bottom"></div>
          
          <div class="flex-div">
            <div class="line-box">
              <a-range-picker 
                class="data-picker"
                v-model="lineYear"
                @change="onSelectTime"
                :disabledDate="fun_disDate"
                :placeholder="['开始日期', '结束日期']"
                format="YYYY-MM-DD" />
              <a-select  class="more-picker" v-model="yearList" @change="onMoreYearChange"  placeholder="年度">
                <a-icon slot="suffixIcon" type="caret-down" />
                <a-select-option :value="item" v-for="(item,index) in yearArry" :key="index">{{item}}</a-select-option>
              </a-select>
              <Line3DChart :DataList="StudyLampList" />
            </div>
            <div class="list-box">
              <div class="item-th head-th">
                <div class="td">序号</div>
                <div class="td">日期</div>
                <div class="td">人数</div>
                <div class="td">占比</div>
              </div>
              <div class="seamless-scroll">
                <vue-seamless-scroll v-if="StudyLampShow" :data="StudyLampList" :class-option="LampOption">
                  <div class="item-th" v-for="(item,index) in StudyLampList" :key="index">
                    <div class="td">{{index + 1}}</div>
                    <div class="td">{{item.name}}</div>
                    <div class="td">{{item.value}}</div>
                    <div class="td">{{item.proportion}}%</div>
                  </div>
                </vue-seamless-scroll>
              </div>
            </div>
          </div>
        </div>
        <div class="flex-div">
          <div class="echarts-inner">
            <div class="title-bg">
              <span>用户身份分布</span>
            </div>
            <div class="border-container border-container-left-bottom"></div>
            <div class="border-container border-container-right-bottom"></div>
            <Bar3DChart :DataList="identityList" />
          </div>
          <div class="echarts-inner">
            <div class="title-bg">
              <span>学历分布</span>
            </div>
            <div class="border-container border-container-left-bottom"></div>
            <div class="border-container border-container-right-bottom"></div>
            <Pie3DChart v-if="StudyLearnShow" :DataList="educationList" />
          </div>
          <div class="echarts-inner">
            <div class="title-bg">
              <span>年龄分布</span>
            </div>
            <div class="border-container border-container-left-bottom"></div>
            <div class="border-container border-container-right-bottom"></div>
            <Cycle3DChart :DataList="ageList" />
          </div>
        </div>
      </div>
      <!-- 新建编辑弹窗 -->
      <a-modal v-model="productShow" width='700px' title="选择商品课程"  :centered='true' :closable='false'>
        <div class="search-box">
          <a-input class="search-input" v-model="productForm.name" placeholder="请输入商品名称" />
          <a-button class="search-btn" type="primary" @click="getCourseList(false)">搜索</a-button>
        </div>

        <div class="table-box">
          <a-table class="table-template1" 
            :row-selection="{
              columnTitle: '选择',
              selectedRowKeys: selectedProductRowKeys,
              onChange: onSelectProduct,
              type: 'radio',
              columnWidth: '70px',
            }" 
            bordered
            :rowKey="item=> item.objectId"
            :columns="productcolumns" 
            :data-source="courseList"
            :loading="productLoad"
            @change="onProductPage"
            :pagination="{
              total:courseTotal,
              current:productForm.page,  
              defaultpageSize:productForm.size, 
              showSizeChanger: true,
              showTotal: function(courseTotal, range){
                return `共${courseTotal}条`
              }
            }">
            <template slot="index" slot-scope="item, row, index">
              {{ (productForm.page - 1) * productForm.size + index + 1 }}
            </template>
          
            <!-- 商品类型 -->
            <template slot="productType" slot-scope="item">
              <!-- 1.课程 2.图书 3.教具 4.模拟考试 5.电子照片 6.延期 7.补考 8.直播 9.其他 -->
              <span v-if="item==1">课程</span>
              <span v-else-if="item==2">图书</span>
              <span v-else-if="item==3">教具</span>
              <span v-else-if="item==4">模拟考试</span>
              <span v-else-if="item==5">电子照片</span>
              <span v-else-if="item==6">延期</span>
              <span v-else-if="item==7">补考</span>
              <span v-else-if="item==8">直播</span>
              <span v-else-if="item==9">其他</span>
              <span v-else>-</span>
            </template>
          </a-table>
        </div>
        <template slot="footer">
          <a-button type="primary" @click="onAskCourse">确认</a-button>
          <a-button @click="productShow = false">取消</a-button>
        </template>
      </a-modal>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from 'vue-seamless-scroll';  // 引入跑马灯组件
import Line3DChart from '@/components/Echarts/3DlineChart.vue' // 引入3D饼图组件
import Bar3DChart from '@/components/Echarts/3DBarChart.vue' // 引入3D饼图组件
import Pie3DChart from '@/components/Echarts/3DPieChart.vue' // 引入3D饼图组件
import Cycle3DChart from '@/components/Echarts/3DCycleChart.vue' // 引入3D饼图组件
import { fun_formatData } from '@/unit/fun.js'
import moment from "moment";
const productcolumns = [{
    title: "序号",
    align: "center",
    width:'70px',
    dataIndex: "index",
    scopedSlots: {
      customRender: "index",
    },
  },
  // {
  //   title: "商品类型",
  //   align: "center",
  //   width:'140px',
  //   dataIndex: "productType",
  //   scopedSlots: {
  //     customRender: "productType",
  //   },
  // },
  {
    title: "商品名称",
    ellipsis:true,
    align: "center",
    dataIndex: "productName",
  },
];
export default {
  // 可用组件的哈希表
  components: {vueSeamlessScroll,Pie3DChart,Bar3DChart,Cycle3DChart, Line3DChart},
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      productcolumns,
      /* 跑马灯配置 */
      LampOption: {
        step: 0.2, // 数值越大速度滚动越快
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 2000
      },

      /* 商品课程配置 */
      productForm: {
        name:'',
        type: 1,  // 1.课程 2.图书 3.教具
        page:1,
        size:10,
      },
      productShow:false,
      productLoad: false,
      courseId:'', // 课程id
      courseName:'', // 课程名称
      courseList:[], // 课程列表
      courseTotal:0, // 课程总数量
      selectedProductRowKeys: [],
      selectedProductDetail: {},

      lineType: 9,
      lineYear: [],
      yearArry:[],
      yearList: undefined,

      /* 各个图表数据 */
      vivewList:[], // 顶部数据
      StudyLampList: [],// 学习情况
      StudyLampShow:false,
      StudyLearnShow:false,
      identityList:[], // 用户身份
      educationList:[], // 学历
      ageList:[], // 年龄
    }
  },
  // 事件处理器
  methods: {
    // 区间日期选择
    onSelectTime(date, dateString) {
      if(!date.length){
        this.getList('rpt_courseDashboardData', 0)
        return
      }
      this.lineYear = dateString
      const time1 = dateString[0] + " 00:00:00"
      const time2 = dateString[1] + " 23:59:59"

      this.lineType = 9
      this.yearList = undefined
      this.getList('rpt_courseDashboardData', 9, time1, time2)
    },

    // 年度选择
    onMoreYearChange(arry){
      this.lineType = 4
      this.lineYear = []
      this.getList('rpt_courseDashboardData', 4, arry)
    },
    // 选择商品
    onSelectProduct(selectedRowKeys,v){
      this.selectedProductRowKeys = selectedRowKeys
      this.selectedProductDetail = v[0]
    },
    fun_disDate(current) {
      return current && current > moment().endOf("day");
    },
    // 商品分页
    onProductPage(e) {
      this.productForm.page = e.current;
      this.productForm.size = e.pageSize;
      this.getCourseList()
    },
    // 查询课程列表
    getCourseList(type){
      this.productLoad = true
      this.$ajax({
        method: 'get',
        url: '/hxclass-management/product-archives/manage/select-win',
        params: this.productForm
      }).then((res) => {
        if (res.code == 200 && res.success) {
          this.courseTotal = res.data.total;
          this.courseList = res.data.records;

          // type 初始值
          if(res.data.total && type){
            this.courseId = res.data.records[0].objectId
            this.courseName = res.data.records[0].productName
            this.selectedProductRowKeys = [this.courseId]

            const date = new Date();
            const Year = date.getFullYear();
            const Month = ((date.getMonth() + 1) < 10 ? ('0' + (date.getMonth() + 1)) : (date.getMonth() + 1));
            const Day = date.getDate();
            const YearMonth = Year + "-" + Month + "-01 00:00:00"; // 查询年月数据需要拼接该格式
            const DayLast = Year + "-" + Month + "-" + Day + " 23:59:59"; 
            this.lineYear = [Year + "-" + Month + "-" + '01', Year + "-" + Month + "-" + Day]

            this.getList('rpt_courseUserDashboardData', 0) // 统计图表
            this.getList('rpt_courseDashboardData', 9, YearMonth, DayLast) // 查询学习折线图
          }
        } else {
          this.$message.error(res.message);
        }
        this.productLoad = false
      });
    },
    // 查询课程列表
    getList(pName, dateType, time1, time2){
      let dataJson = {
        dateType: dateType, // 0-全部,1-本日,2-本周,3-本月,4-指定年份,5-指定月份,6-本年，9-自定义起始结束日期
        courseId: this.courseId,
        time1: time1,
        time2: time2,
      };
      if(pName == 'rpt_courseUserDashboardData'){
        this.StudyLearnShow = false
      }
      this.$ajax({
        method: 'POST',
        url: '/hxclass-management/procedure/getProcedure',
        params: {
          inString: JSON.stringify(dataJson),
          pName: pName
        }
      }).then((res) => {
        if (res.code == 200 && res.success) {
          if (res.data) {
            if(pName == 'rpt_courseUserDashboardData'){
              this.StudyLearnShow = false

              let resultArr = res.data.split("$$"); // 按数据code和data进行分割区分
              let resultCode = resultArr[0].split("@@");
              let resultData = resultArr[1].split("@@");

              let vivewList = []
              let identityList = []
              let educationList = []
              let ageList = []
              resultData.forEach((element,index) => {
                let str = resultCode + '$$' + element
                let res = fun_formatData(str)
                if(index == 0){ // 顶部总览数据
                  vivewList = res.data
                } else if (index == 1){
                  identityList = res.data
                } else if (index == 2){
                  educationList = res.data
                } else if (index == 3){
                  ageList = res.data
                }
              });
              // 表盘数据整理
              if(vivewList.length){
                let arr = []
                const dataType = {0:'在学人数',1:'已学人数',2:'通过人数',3:'考试人数'}
                vivewList.forEach(element => {
                  arr.push({
                    name: dataType[element.value],
                    value: element.name
                  })
                });
                this.vivewList = arr
              }
              if(identityList.length){
                let arr = []
                const dataType = {1:'家长',2:'从业者',4:'家长+从业者'}
                identityList.forEach(element => {
                  if(element.value == 1 || element.value == 2 || element.value == 4){
                    arr.push({
                      name: dataType[element.value],
                      value: element.name
                    })
                  }
                });
                this.identityList = arr
              }
              if(educationList.length){
                let arr = []
                const dataType = {1:'博士后',2:'博士研究生',3:'硕士研究生',5:'本科',6:'大专',7:'高中',8:'初中',9:'小学',10:'未知',11:'中专',99:'其他'}
                educationList.forEach(element => {
                  arr.push({
                    name: dataType[element.value],
                    value: Number(element.name) / 50
                  })
                });
                this.educationList = arr
                this.StudyLearnShow = true
              }
              if(ageList.length){
                let arr = []
                const dataType = {0:'其他',1:'1-20岁',2:'21-25岁',3:'26-30岁',4:'31-35岁',5:'36-40岁',6:'40岁以上'}
                ageList.forEach(element => {
                  arr.push({
                    name: dataType[element.value],
                    value: Number(element.name)
                  })
                });
                this.ageList = arr
              }
            } else if(pName == 'rpt_courseDashboardData'){
              this.StudyLampShow = false
              let resultData = fun_formatData(res.data);
              let arr = resultData.data
              this.StudyLampList = arr
              let num = 0
              arr.forEach(element => {
                num = num + Number(element.value)
              });
              this.StudyLampList.forEach(element => {
                if(num){
                  element.proportion = ((Number(element.value) / num) * 100).toFixed(2)
                } else {
                  element.proportion = '0.00'
                }
              });
              this.StudyLampShow = true
            }
          } else {
            if(pName == 'rpt_courseUserDashboardData'){
              this.vivewList = [
                {name: '在学人数', value: 0},
                {name: '已学人数', value: 0},
                {name: '通过人数', value: 0},
                {name: '考试人数', value: 0}
              ]
              this.identityList = [
                {name: '家长', value: 0},
                {name: '从业者', value: 0},
                {name: '家长+从业者', value: 0},
              ]
              this.educationList = [
                {name: '无数据', value: 0}
              ]
              this.ageList = []
            } else if(pName == 'rpt_courseDashboardData'){
              this.StudyLampList = []
            }
          }
        } else {
          this.$message.error(res.message);
        }
      });
    },

    // 课程更换
    onAskCourse(){
      this.courseId = this.selectedProductDetail.objectId
      this.courseName = this.selectedProductDetail.productName


      this.getList('rpt_courseUserDashboardData', 0)

      const time1 = this.lineYear[0] + " 00:00:00"
      const time2 = this.lineYear[1] + " 23:59:59"
      if(this.lineType == 9){
        this.getList('rpt_courseDashboardData', this.lineType, time1, time2)
      }else if (this.lineType == 4){
        this.getList('rpt_courseDashboardData', this.lineType, this.yearList)
      }
      this.productShow = false
    }
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getCourseList(true)
  },
  // 生命周期-实例挂载后调用
  mounted () {
    // 获取2020年至今年的所有年份
    const currentYear = new Date().getFullYear();
    const startYear = 2020;
    this.yearArry = []
    for (let year = startYear; year <= currentYear; year++) {
      this.yearArry.push(year);
    }
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.main-wrap{
  background-color: #0F1D41 !important;
  min-height: 100%;
  padding: 50px;
  .main-margin{
    width: 100%;
    // width: 1400px;
    // margin: 32px auto;
    .head-title{
      width: 100%;
      height: 58px;
      margin-bottom: 70px;
      background-image: url("../../../assets/image/dataCenter/head-title.png");
      background-size: 100% 100%;
      background-repeat: no-repeat;
      position: relative;
      overflow: auto;
      .text{
        position: absolute;
        left: 50%;
        margin-left: -102px;
        top: 7px;
        font-size: 26px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 600;
        color: #FFFFFF;
        line-height: 40px;
      }
      .num-list{
        display: flex;
        font-weight: 400;
        color: #FFFFFF;
        margin-top: 20px;
        margin-left: 31px;
        .num-item{
          text-align: center;
          margin-right: 45px;
          p{
            font-size: 12px;
            margin-top: 10px;
          }
        }
      }
    }
    .echarts-box{
      .course-inner{
        background: rgba(140,167,255,0.19);
        box-shadow: inset 0px 0px 4px 0px rgba(255,255,255,0.25);
        border-radius: 4px 4px 4px 4px;
        padding: 4px 18px;
        margin-top: 24px;
        font-size: 15px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #A8C5E7;
        line-height: 24px;
        width: 400px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
        .icon{
          width: 16px;
          margin-left: 40px;
        }
      }
      .people-inner{
        align-items: center;
        margin-top: 28px;
        .icon{
          width: 50px;
        }
        .people-item{
          height: 35px;
          line-height: 27px;
          font-size: 12px;
          width: calc((100% - 200px) / 4);
          color: #FFFFFF;
          background: rgba(0,22,79,0.11);
          box-shadow: inset 0px 0px 4px 0px rgba(255,255,255,0.25);
          text-align: center;
          border-top: 2px solid #394890;
          border-bottom: 2px solid #394890;
          position: relative;
          span{
            font-size: 18px;
            margin: 0 10px;
            
          }
        }
        .people-item::after{
          content: '';
          background-image: url("../../../assets/image/dataCenter/title-icon.png");
          width: 36px;
          height: 5px;
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: absolute;
          left: 50%;
          top: -5px;
          margin-left: -18px;
        }
      }
      .echarts-inner{
        margin-top: 30px;
        width: 100%;
        padding: 15px;
        background: rgba(140,167,255,0.19);
        border: 5px solid rgba(102,117,255,0.28);
        position: relative;
        .title-bg{
          width: 348px;
          height: 28px;
          background-image: url("../../../assets/image/dataCenter/title-bg.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          position: absolute;
          left: 50%;
          top: -17px;
          margin-left: -174px;
          text-align: center;
          span{
            font-size: 18px;
            font-family: PingFang SC, PingFang SC;
            font-weight: 600;
            color: #14174D;
            line-height: 28px;
            text-shadow: inset 1px 1px 1px rgba(0,0,0,0.5);
          }
        }
      }
      .echarts-inner::after, .echarts-inner::before, .border-container{
        content: '';
        z-index: 10;
        position: absolute;
        width: 18px;
        height: 18px;
        background: rgba(140,167,255,0);
        border: 5px solid #636CA6;
      }
      /*下面是给四个角框增加效果*/
      .echarts-inner::after{
        top: -5px;
        left: -5px;
        border-right: none;
        border-bottom: none;
      }
      .echarts-inner::before{
        top: -5px;
        right: -5px;
        border-left: none;
        border-bottom: none;
      }
      .echarts-inner .border-container-left-bottom{
        bottom: -5px;
        left: -5px;
        border-right: none;
        border-top: none;
      }
      .echarts-inner .border-container-right-bottom{
        bottom: -5px;
        right: -5px;
        border-left: none;
        border-top: none;
      }

      .flex-div{
        display: flex;
        justify-content: space-between;
        .echarts-inner{
          width: calc((100% - 16px) / 3);
        }
      }

      .line-box{
        flex: 1;
        .data-picker{
          width: 300px;
        }
        .more-picker{
          width: 100px;
          margin-left: 30px;
        }
        /deep/ .ant-input, .ant-select-selection{
          background-color: rgba(140,167,255,0.19);
          color: #A8C5E7;
        }
        /deep/ .ant-select-selection{
          background-color: rgba(140,167,255,0.19);
        }
        /deep/ .ant-select-selection__placeholder{
          color: #A8C5E7;
        }
        /deep/ .ant-select-selection-selected-value{
          color: #A8C5E7;
        }
        /deep/ .ant-calendar-picker-clear{
          background: rgba(140,167,255,0.19);
          border-radius: 50%;
        }
        /deep/ .ant-calendar-picker-icon{
          color: #A8C5E7;
        }
      }

      .list-box{
        width: 30%;
        height: 360px;
        background: linear-gradient(to left, #28ABF8, #28ABF8) left top no-repeat, linear-gradient(to bottom, #28ABF8, #28ABF8) left top no-repeat, linear-gradient(to left, #28ABF8, #28ABF8) right top no-repeat, linear-gradient(to bottom, #28ABF8, #28ABF8) right top no-repeat, linear-gradient(to left, #28ABF8, #28ABF8) left bottom no-repeat, linear-gradient(to bottom, #28ABF8, #28ABF8) left bottom no-repeat, linear-gradient(to left, #28ABF8, #28ABF8) right bottom no-repeat, linear-gradient(to left, #28ABF8, #28ABF8) right bottom no-repeat;
        background-size: 3px 10px, 10px 3px, 3px 10px, 10px 3px;
        transition: all .4s ease;
        border: 1px solid transparent;
        .item-th{
          display: flex;
          color: #A8C5E7;
          font-size: 13px;
          .td{
            width: calc(100% / 4);
            text-align: center;
          }
        }
        .head-th{
          height: 40px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          background: rgba(94,113,161,0.13);
          .td{
            line-height: 40px;
          }
        }
        .seamless-scroll{
          height: calc(100% - 40px);
          background: rgba(98,135,231,0.16);
          overflow: hidden;
          .item-th{
            padding: 0;
            height: 35px;
            line-height: 35px;
            border-bottom: 1px solid #273765;
          }
        }
      }
    }
  }
}
/deep/.ant-calendar{
  background-color: rgba(140,167,255,0.19) !important;
}
.search-box{
  display: flex;
  margin-bottom: 30px;
  .search-btn{
    margin-left: 30px;
  }
}
// /deep/ .ant-modal-header{
//   background-color: #0F1D41;
// }
// /deep/ .ant-modal-content{
//   background-color: #0F1D41;
//   color: #A8C5E7
// }
// /deep/ .ant-modal-title{
//   color: #A8C5E7
// }
// /deep/ .ant-table table{
//   background-color: #263765;
// }
// /deep/ .ant-pagination{
//   color: #A8C5E7
// }
// /deep/ .ant-table-tbody > tr > td{
//   color: #A8C5E7
// }
// /deep/ .ant-table-thead{
//   background-color: #0F1D41;
// }
</style>
