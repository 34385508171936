<template>
  <!-- 2d-饼图 -->
  <div >
    <div class="myChart-line" ref="myChart07"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Array,
      default: () => [],
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart07);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart07);
      }
      let optionsData = this.DataList
      let xData = []
      let total = 0
      this.DataList.forEach(element => {
        xData.push(element.name)
        total = total + element.value
      });
      const series = this.getPie3D(optionsData, .6);
      series.push({
        name: "pie2d",
        type: "pie",
        label: {
          show: true,
          opacity: 1,
          fontSize: 14,
          lineHeight: 20,
          textStyle: {
            fontSize: 14,
            color: "#fff",
          },
          formatter: "{b} {d}%",
        },
        labelLine: {
          length: 10,
          length2: 10,
        },
        startAngle: 2, //起始角度，支持范围[0, 360]。
        clockwise: false, //饼图的扇区是否是顺时针排布。上述这两项配置主要是为了对齐3d的样式
        radius: ["50%", "60%"],
        center: ["30%", "50%"],
        data: optionsData,
        itemStyle: {
          opacity: 0,
        },
        labelLine: {
          show: false,
        },
        label: {
          show: false,
          position: "center",
        },
      });
      // 传入数据生成 option ; getPie3D(数据，透明的空心占比（调节中间空心范围的0就是普通饼1就很镂空）)
      var option = {
        legend: {
          show: true,
          orient: "vertical",
          data: xData,
          top: "center",
          itemGap: 14, // 间距
          itemHeight: 14,
          itemWidth: 24,
          right: "5%",
          textStyle: {
            color: "#fff",
            fontSize: 12,
            rich: {
              name: {
                width: 60,
                fontSize: 12,
                color: "#B0D8DF",
                fontFamily: "Source Han Sans CN",
              },
              value: {
                width: 30,
                fontSize: 12,
                padding: [0, 5, 0, 5],
                color: "#fff",
                fontFamily: "Source Han Sans CN",
              },
              A: {
                fontSize: 12,
                color: "#B0D8DF",
                fontFamily: "Source Han Sans CN",
              },
              rate: {
                width: 30,
                fontSize: 12,
                padding: [0, 5, 0, 10],
                color: "#10DD24",
                fontFamily: "Source Han Sans CN",
              },
              B: {
                fontSize: 12,
                color: "#B0D8DF",
                fontFamily: "Source Han Sans CN",
              },
            },
          },
          // formatter: function (name) {
          //   let res = optionsData.filter((v) => v.name === name);
          //   res = res[0] || {};
          //   const p = res.value;
          //   // const A = res.percent;
          //   return "{name|" + name + "：}" + "{value|" + p + "}人";
          // },
          formatter: function (name) {
            let total = 0;
            let target;
            for (let i = 0; i < optionsData.length; i++) {
              total += optionsData[i].value;
              if (optionsData[i].name === name) {
                target = optionsData[i].value;
              }
            }
            let arr = [
              "{name|" + name + "}",
              "{value|" + ( target * 50).toFixed(0) + "}{A|人}",
              // "{rate|" + ((target / total) * 100).toFixed(1) + "}{B|%}",
            ];
            return arr.join("  ");
          },
        },
        animation: true,
        tooltip: {
          backgroundColor: "rgba(64, 180, 176, 0.6)",
          borderColor: "rgba(64, 180, 176, 0.6)",
          textStyle: {
            color: "#fff",
            fontSize: 16,
          },
          formatter: (params) => {
            if(params.seriesName == 'pie2d'){
              return
            }
            let bizhong = 0
            optionsData.forEach(element => {
              if(element.name == params.seriesName){
                bizhong = element.value / total
              }
            });
          
            if (
              params.seriesName !== "mouseoutSeries" &&
              params.seriesName !== "pie2d"
            ) {
              return `${
                params.seriesName
              }<br/><span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${
                params.color
              };"></span>${
                (bizhong * 100).toFixed(0)  + "%"
              }`;
            }
          },
        },
        backgroundColor: "#263765",
        labelLine: {
          show: true,
          lineStyle: {
            color: "#7BC0CB",
          },
          normal: {
            show: true,
            length: 10,
            length2: 10,
          },
        },
        label: {
          show: true,
          position: "outside",
          formatter: "{b} \n{c}\n{d}%",
          textStyle: {
            color: "rgba(176, 216, 223, 1)",
            fontSize: 24,
          },
        },
        xAxis3D: {
          min: -1,
          max: 1,
        },
        yAxis3D: {
          min: -1,
          max: 1,
        },
        zAxis3D: {
          min: -1,
          max: 1,
        },
        grid3D: {
          show: false,
          boxHeight: 1,
          //top: '30%',
          left: "6%",
          top: -10,
          // right: "3%",
          width: "50%",
          // environment: "rgba(255,255,255,0)",
          viewControl: {
            distance: 220,
            alpha: 30,  // 转盘倾斜度
            beta: 15,
            autoRotate: true, // 自动旋转
            rotateSensitivity: 1,
            zoomSensitivity: 0,
            panSensitivity: 0,
          },
        },
        series: series,
      };
      //将配置项设置进去
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },

    // 生成模拟 3D 饼图的配置项
    getPie3D(pieData, internalDiameterRatio) {
      let series = [];
      let sumValue = 0;
      let startValue = 0;
      let endValue = 0;
      let legendData = [];
      let k =
        typeof internalDiameterRatio !== "undefined"
          ? (1 - internalDiameterRatio) / (1 + internalDiameterRatio)
          : 1 / 3;

      // 为每一个饼图数据，生成一个 series-surface 配置
      for (let i = 0; i < pieData.length; i++) {
        sumValue += pieData[i].value;

        let seriesItem = {
          name:
            typeof pieData[i].name === "undefined"
              ? `series${i}`
              : pieData[i].name,
          type: "surface",
          parametric: true,
          wireframe: {
            show: false,
          },
          pieData: pieData[i],
          pieStatus: {
            selected: false,
            hovered: false,
            k: 1 / 10,
          },
        };

        if (typeof pieData[i].itemStyle != "undefined") {
          let itemStyle = {};

          typeof pieData[i].itemStyle.color != "undefined"
            ? (itemStyle.color = pieData[i].itemStyle.color)
            : null;
          typeof pieData[i].itemStyle.opacity != "undefined"
            ? (itemStyle.opacity = pieData[i].itemStyle.opacity)
            : null;

          seriesItem.itemStyle = itemStyle;
        }
        series.push(seriesItem);
      }

      // 使用上一次遍历时，计算出的数据和 sumValue，调用 getParametricEquation 函数，
      // 向每个 series-surface 传入不同的参数方程 series-surface.parametricEquation，也就是实现每一个扇形。
      for (let i = 0; i < series.length; i++) {
        endValue = startValue + series[i].pieData.value;
        series[i].pieData.startRatio = startValue / sumValue;
        series[i].pieData.endRatio = endValue / sumValue;
        series[i].parametricEquation = this.getParametricEquation(
          series[i].pieData.startRatio,
          series[i].pieData.endRatio,
          false,
          false,
          k,
          series[i].pieData.value
        );

        startValue = endValue;

        legendData.push(series[i].name);
      }

      // // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
      // series.push({
      //   name: "mouseoutSeries",
      //   type: "surface",
      //   parametric: true,
      //   wireframe: {
      //     show: false,
      //   },
      //   itemStyle: {
      //     opacity: 0.1,
      //     color: "#E1E8EC",
      //   },
      //   parametricEquation: {
      //     u: {
      //       min: 0,
      //       max: Math.PI * 2,
      //       step: Math.PI / 20,
      //     },
      //     v: {
      //       min: 0,
      //       max: Math.PI,
      //       step: Math.PI / 20,
      //     },
      //     x: function (u, v) {
      //       return ((Math.sin(v) * Math.sin(u) + Math.sin(u)) / Math.PI) * 2;
      //     },
      //     y: function (u, v) {
      //       return ((Math.sin(v) * Math.cos(u) + Math.cos(u)) / Math.PI) * 2;
      //     },
      //     z: function (u, v) {
      //       return Math.cos(v) > 0 ? -0.5 : -5;
      //     },
      //   },
      // });
      // // // 补充一个透明的圆环，用于支撑高亮功能的近似实现。
      // series.push({
      //   name: "mouseoutSeries",
      //   type: "surface",
      //   parametric: true,
      //   wireframe: {
      //     show: false,
      //   },
      //   itemStyle: {
      //     opacity: 0.1,
      //     color: "#E1E8EC",
      //   },
      //   parametricEquation: {
      //     u: {
      //       min: 0,
      //       max: Math.PI * 2,
      //       step: Math.PI / 20,
      //     },
      //     v: {
      //       min: 0,
      //       max: Math.PI,
      //       step: Math.PI / 20,
      //     },
      //     x: function (u, v) {
      //       return ((Math.sin(v) * Math.sin(u) + Math.sin(u)) / Math.PI) * 2;
      //     },
      //     y: function (u, v) {
      //       return ((Math.sin(v) * Math.cos(u) + Math.cos(u)) / Math.PI) * 2;
      //     },
      //     z: function (u, v) {
      //       return Math.cos(v) > 0 ? -5 : -7;
      //     },
      //   },
      // });
      // series.push({
      //   name: "mouseoutSeries",
      //   type: "surface",

      //   parametric: true,
      //   wireframe: {
      //     show: false,
      //   },
      //   itemStyle: {
      //     opacity: 0.1,
      //     color: "#E1E8EC",
      //   },
      //   parametricEquation: {
      //     u: {
      //       min: 0,
      //       max: Math.PI * 2,
      //       step: Math.PI / 20,
      //     },
      //     v: {
      //       min: 0,
      //       max: Math.PI,
      //       step: Math.PI / 20,
      //     },
      //     x: function (u, v) {
      //       return (
      //         ((Math.sin(v) * Math.sin(u) + Math.sin(u)) / Math.PI) * 2.2
      //       );
      //     },
      //     y: function (u, v) {
      //       return (
      //         ((Math.sin(v) * Math.cos(u) + Math.cos(u)) / Math.PI) * 2.2
      //       );
      //     },
      //     z: function (u, v) {
      //       return Math.cos(v) > 0 ? -7 : -7;
      //     },
      //   },
      // });
      // // return series;
      return series;
    },

    // 生成扇形的曲面参数方程，用于 series-surface.parametricEquation
    getParametricEquation(startRatio, endRatio, isSelected, isHovered, k, h) {
      // 计算
      let midRatio = (startRatio + endRatio) / 2;

      let startRadian = startRatio * Math.PI * 2;
      let endRadian = endRatio * Math.PI * 2;
      let midRadian = midRatio * Math.PI * 2;

      // 如果只有一个扇形，则不实现选中效果。
      // if (startRatio === 0 && endRatio === 1) {
      //     isSelected = false;
      // }
      isSelected = false;
      // 通过扇形内径/外径的值，换算出辅助参数 k（默认值 1/3）
      k = typeof k !== "undefined" ? k : 1 / 3;

      // 计算选中效果分别在 x 轴、y 轴方向上的位移（未选中，则位移均为 0）
      let offsetX = isSelected ? Math.sin(midRadian) * 0.1 : 0;
      let offsetY = isSelected ? Math.cos(midRadian) * 0.1 : 0;

      // 计算高亮效果的放大比例（未高亮，则比例为 1）
      let hoverRate = isHovered ? 1.05 : 1;

      // 返回曲面参数方程
      return {
        u: {
          min: -Math.PI,
          max: Math.PI * 3,
          step: Math.PI / 32,
        },

        v: {
          min: 0,
          max: Math.PI * 2,
          step: Math.PI / 20,
        },

        x: function (u, v) {
          if (u < startRadian) {
            return (
              offsetX +
              Math.cos(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetX + Math.cos(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetX + Math.cos(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        y: function (u, v) {
          if (u < startRadian) {
            return (
              offsetY +
              Math.sin(startRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          if (u > endRadian) {
            return (
              offsetY + Math.sin(endRadian) * (1 + Math.cos(v) * k) * hoverRate
            );
          }
          return offsetY + Math.sin(u) * (1 + Math.cos(v) * k) * hoverRate;
        },

        z: function (u, v) {
          if (u < -Math.PI * 0.5) {
            return Math.sin(u);
          }
          if (u > Math.PI * 2.5) {
            return Math.sin(u) * h * 0.1;
          }
          return Math.sin(v) > 0 ? 1 * h * 0.1 : -1;
        },
      };
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },

  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  width: 100%;
  height: 320px;
}
</style>
