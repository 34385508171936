<template>
  <!-- 2d-饼图 -->
  <div>
    <div class="myChart-line" ref="myChart08"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Array,
      default: () => [],
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart08);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart08);
      }
      let resultData = this.DataList

      var option = {
        tooltip: {
          show: true,
          trigger: "item",
          formatter: function (params) {
            var name = params.name;
            return params.percent + "%"
          },
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: ["0%", "70%"],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 100,
              borderColor: "#ffffff",
              borderWidth: 2,
            },
            label: {
              show: true,
              position: "center",
            },
            labelLine: {
              length: 30,
              show: true,
            },
            data: resultData,
            label: {
              show: true,
              formatter: function (params) {
                var name = params.name;
                var percent = params.value;
                return name + "\n" + percent + "人";
              },
              textStyle: {
                color: "rgba(176, 216, 223, 1)",
                fontSize: 14,
              },
            },
          },
        ],
      }
      //将配置项设置进去
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },

  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  width: 100%;
  height: 320px;
}
</style>
