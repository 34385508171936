<template>
  <!-- 折线图 -->
  <div>
    <div class="myChart-line" ref="myChart09"></div>
  </div>
</template>

<script>
import * as echarts from "echarts";
import "echarts-gl";
export default {
  // 可用组件的哈希表
  components: {},
  // 接收传值
  props: {
    DataList: {
      type: Array,
      default: () => [],
    },
  },
  // 数据对象
  data() {
    return {};
  },
  // 事件处理器
  methods: {
    myChartInit() {
      var myChart = echarts.getInstanceByDom(this.$refs.myChart09);
      if (!myChart) {
        myChart = echarts.init(this.$refs.myChart09);
      }
      let xData = []
      let yDataList = []
      this.DataList.forEach(element => {
        xData.push(element.name)
        yDataList.push(element.value)
      });

      const yData = [
        {
          name: "学习人数",
          data: yDataList,
        },
      ];

      const hexToRgb = (hex) => {
        // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
        const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
        // eslint-disable-next-line no-param-reassign
        hex = hex.replace(
          shorthandRegex,
          (m, r, g, b) => r + r + g + g + b + b
        );

        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

        /* eslint-disable indent */
        return result
          ? {
              r: parseInt(result[1], 16),
              g: parseInt(result[2], 16),
              b: parseInt(result[3], 16),
            }
          : null;
        /* eslint-enable */
      };

      const xAxisFn = (xData) => {
        return [
          {
            type: "category",
            boundaryGap: false,
            data: xData,
            axisLine: { show: false },
            axisTick: { show: false },
            // 以下需要安装date-fns
            // axisLabel: {
            //    show: true,
            //    formatter: name => {
            //       return format(new Date(name), 'HH:mm')
            //    },
            // },
            // axisPointer: {
            //    show: true,
            //    label: {
            //       formatter: obj => {
            //          let name = obj.value
            //          let start = format(subHours(new Date(name), 1), 'HH:mm')
            //          let end = format(new Date(name), 'HH:mm')
            //          return `${start}~${end}`
            //       },
            //    },
            // },
          },
        ];
      };

      const colorList = ["#107FFF", "#55D1FD"];
      var option = {
        series: yData.map((item, index) => {
          const rgb = hexToRgb(colorList[index]);
          const end = `rgba(${rgb.r},${rgb.g},${rgb.b},0)`;
          return {
            name: item.name,
            data: item.data,
            type: "line",
            smooth: true,
            showSymbol: false,
            // symbol: 'none',
            symbolSize: 10,
            emphasis: { focus: "series" },
            animationDuration: 2500,
            animationEasing: "cubicInOut",
            lineStyle: {
              width: 3,
              color: '#5B8FF9',
            },
            areaStyle: {
              width: 4,
              opacity: 0.25,
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  { offset: 0.389, color: colorList[index] },
                  { offset: 1, color: end },
                ],
                global: false,
              },
            },
          };
        }),
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
          },

          // formatter: '{a}: {c}',
          textStyle: {
            color: "#fafafa",
          },
          borderColor: "transparent",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          extraCssText: "backdrop-filter: blur(6px);",
        },
        grid: {
          top: "20%",
          left: "2%",
          right: "5%",
          bottom: "0%",
          containLabel: true,
        },
        legend: {
          show: false,
          right: 20,
          icon: "roundRect",
          itemHeight: 5,
          itemWidth: 10,
          itemGap: 40,
          textStyle: {
            fontSize: 12,
          },
          itemStyle: {
            borderColor: "transparent",
            borderWidth: 6,
          },
        },
        xAxis: xAxisFn(xData),
        yAxis: [
          {
            type: "value",
          },
        ],
      };

      //将配置项设置进去
      myChart.setOption(option);
      window.addEventListener("resize", function () {
        myChart.resize();
      });
    },
  },
  // 生命周期-实例挂载后调用
  mounted() {
    this.$nextTick(() => {
      setTimeout(() => {
        this.myChartInit();
      }, 200);
    });
  },

  watch: {
    DataList() {
      this.$nextTick(() => {
        this.myChartInit();
      });
    },
  },
};
</script>

<style lang="less" scoped>
.myChart-line {
  width: 100%;
  height: 320px;
}
</style>
